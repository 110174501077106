



















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import { AccordionItem } from '../../../../dsl/atoms/Accordion'
import { CustomNav } from '../../../../dsl/molecules/Nav'

import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'
import { StructureConfigurable } from '../../../../support/mixins'

import { FooterProps } from './Footer.contracts'
import { FOOTER_COMPONENT_CONFIG_MAP, FOOTER_COMPONENT_KEY, FooterConfig } from './Footer.config'
import { TrustedShopBadge } from './partials/TrustedShopBadge.vue'
import CustomNavWithIcons from './partials/CustomNavWithIcons.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Footer>({
  name: 'Footer',
  components: { TrustedShopBadge },
  created () {
    this.config = this.getComponentConfig(FOOTER_COMPONENT_KEY, { ...FOOTER_COMPONENT_CONFIG_MAP })
  }
})
export class Footer extends StructureConfigurable implements FooterProps {
  @Prop({ type: Object, required: false })
  public badge?: FooterProps['badge']

  @Prop({ type: String, required: false })
  public badgeType?: FooterProps['badgeType']

  @Prop({ type: Array, required: false })
  public contactNavigationLinks?: FooterProps['contactNavigationLinks']

  @Prop({ type: String, required: false })
  public descriptionSection?: FooterProps['descriptionSection']

  @Prop({ type: Array, required: false })
  public deliveryMethodsNavigationLinks?: FooterProps['deliveryMethodsNavigationLinks']

  @Prop({ type: Array, required: false })
  public paymentMethodsNavigationLinks?: FooterProps['paymentMethodsNavigationLinks']

  @Prop({ type: Array, required: false })
  public mainNavigationLinks?: FooterProps['mainNavigationLinks']

  @Prop({ type: Array, required: false })
  public secondaryNavigationLinks?: FooterProps['secondaryNavigationLinks']

  @Prop({ type: Object, required: false })
  public siteAuthor?: FooterProps['siteAuthor']

  @Prop({ type: Object, required: false })
  public siteLogo?: FooterProps['siteLogo']

  @Prop({ type: Array, required: false })
  public socialLinks?: FooterProps['socialLinks']

  @Inject(SiteServiceType, false)
  protected readonly siteService?: ISiteService

  protected config!: FooterConfig

  public get aggregateRating () {
    return this.$store.getters['shared/getTrustedShopAggregateRating']
  }

  public get currentYear (): string {
    return new Date().getFullYear().toString()
  }

  public get hasBadge (): boolean {
    return typeof this.badge !== 'undefined'
  }

  public get hasContactNavLinks (): boolean {
    return typeof this.contactNavigationLinks !== 'undefined'
  }

  public get hasDescriptionSection (): boolean {
    return typeof this.descriptionSection !== 'undefined'
  }

  public get hasDeliveryMethodsLinks (): boolean {
    return typeof this.deliveryMethodsNavigationLinks !== 'undefined'
  }

  public get hasPaymentMethodLinks (): boolean {
    return typeof this.paymentMethodsNavigationLinks !== 'undefined'
  }

  public get hasSiteAuthor (): boolean {
    return typeof this.siteAuthor !== 'undefined'
  }

  public get hasSiteLogo (): boolean {
    return typeof this.siteLogo !== 'undefined'
  }

  public get hasSocialLinksNavLinks (): boolean {
    return typeof this.socialLinks !== 'undefined'
  }

  public get contactNavAsAccordion (): boolean {
    return this.getConfigProperty<boolean>('contactNavAsAccordion')
  }

  public get shouldDisplaySocialNav (): boolean {
    return this.getConfigProperty<boolean>('shouldDisplaySocialNav')
  }

  public get shouldLoadTrusted (): boolean {
    return this.getConfigProperty<boolean>('shouldLoadTrusted')
  }

  /**
   * Build UiAccordion structure from main navigation links
   */
  public get mainNav (): AccordionItem[] | undefined {
    if (!this.mainNavigationLinks) {
      return
    }

    return this.mainNavigationLinks.map((link, index) => {
      return {
        id: `mainNav-${index}`,
        label: typeof link.label === 'undefined' ? '' : String(link.label),
        content: {
          component: CustomNav,
          props: {
            links: link.children
          }
        }
      }
    })
  }

  /**
   * Build UiAccordion structure from secondary navigation links
   */
  public get secondaryNav (): AccordionItem[] | undefined {
    if (!this.secondaryNavigationLinks) {
      return
    }

    return this.secondaryNavigationLinks.map((link, index) => {
      return {
        id: `secondaryNav-${index}`,
        label: typeof link.label === 'undefined' ? '' : String(link.label),
        content: {
          component: CustomNav,
          props: {
            links: link.children
          }
        }
      }
    })
  }

  public get contactNav (): AccordionItem[] | undefined {
    if (!this.contactNavigationLinks) {
      return
    }

    return this.contactNavigationLinks.map((link, index) => {
      return {
        id: `contactNav-${index}`,
        label: typeof link.label === 'undefined' ? '' : String(link.label),
        content: {
          component: CustomNav,
          props: {
            links: link.children
          }
        }
      }
    })
  }

  public get contactNavWithSocials (): AccordionItem[] | undefined {
    if (!this.contactNavigationLinks) {
      return
    }

    return this.contactNavigationLinks.map((link, index) => {
      return {
        id: `contactNav-${index}`,
        label: typeof link.label === 'undefined' ? '' : String(link.label),
        content: {
          component: CustomNavWithIcons,
          props: {
            links: this.socialLinks ? [
              ...link.children,
              ...this.socialLinks[0].children
            ] : [...link.children]
          }
        }
      }
    })
  }

  /**
   * Determines address of the active site.
   */
  public get siteAddress (): string {
    return this.siteService?.getActiveSiteAddress() || '/'
  }

  public get siteName (): string {
    return this.siteService?.getActiveSite().name ?? ''
  }
}

export default Footer
