
/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const FOOTER_COMPONENT_KEY = 'FooterComponent'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface FooterConfig {
  contactNavAsAccordion: boolean
  shouldDisplayCopyrightsInSimple: boolean
  shouldDisplaySiteAuthorInSimple: boolean
  shouldDisplaySocialNav: boolean
  shouldLoadTrusted: boolean
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const FOOTER_COMPONENT_CONFIG_MAP: FooterConfig = {
  contactNavAsAccordion: false,
  shouldDisplayCopyrightsInSimple: false,
  shouldDisplaySiteAuthorInSimple: false,
  shouldDisplaySocialNav: true,
  shouldLoadTrusted: false
}
