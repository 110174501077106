















import { Component, Prop, Vue } from 'vue-property-decorator'

import { NavigationItem } from '../../../../../contexts'

/**
 * Custom driver for displaying contact navigation and social navigation with icons
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<CustomNavWithIcons>({
  name: 'CustomNavWithIcons'
})
export class CustomNavWithIcons extends Vue {
  @Prop({ type: Array, required: true, default: [] })
  public readonly links!: Array<NavigationItem>
}

export default CustomNavWithIcons
